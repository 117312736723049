.confirm-delete-panel{

    width: 25vw;
    height: 35vh;
    display: inline-block;

    /* transform: translate(-50%,-50%); */
    background-color: black;
    /* z-index: 9999; */
}
.confirm-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.confirm-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
}